import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';
import { ApiService } from './api.service';

import { WorkTime } from '../model';

export const WorkLogService = new InjectionToken<BaseFeatureModuleService<WorkTime>>('WorkLogService');

export function workLogSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<WorkTime> {
  return new BaseFeatureModuleService<any>(
    apiSvc, 'time-tracking/work-time',
    {
      getItems: { expand: 'Park,User,ActivityType' },
      getItem: { }
    }
  );
}
