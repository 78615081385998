import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { CanDeactivateGuardService, moduleImportGuard } from './guard';
import { WINDOW, windowFactory } from './window-factory';

import { HeaderComponent } from './header/header.component';

import { DynamicFormModule } from './dynamic-form';

import { AuthGuardService, AuthService, TokenService } from './auth';
import {
    ApiService,
    AppService,
    CustomPreloadingStrategyService,
    ParkService,
    parkSvcFactory,
    RoleService,
    UserService,
    userSvcFactory,
    BeltCategoryService,
    beltCategorySvcFactory,
    BeltService,
    beltSvcFactory,
    SupplierService,
    supplierSvcFactory,
    ServiceBeltService,
    serviceBeltSvcFactory,
    ServiceBeltServiceHistory,
    serviceBeltHistorySvcFactory,
    DailyReportService,
    dailyReportSvcFactory,
    RoutePartCategoryService,
    routePartCategorySvcFactory,
    CountryService,
    CheckTypeService,
    BeltStatusService,
    SettingsService,
    UtilService,
    RouteInspectionTypeService,
    routeInspectionTypeSvcFactory,
    BeltPartTypeService,
    beltPartTypeSvcFactory,
    BeltPartCategoryService,
    beltPartCategorySvcFactory,
    RouteService,
    routeSvcFactory,
    ServiceRouteService,
    serviceRouteSvcFactory,
    ComplaintReportService,
    complaintReportSvcFactory,
    RescueReportService,
    rescueReportSvcFactory,
    IncidentReportService,
    incidentReportSvcFactory,
    IncidentReportHandlingService,
    incidentReportHandlingSvcFactory,
    ToastService,
    HttpErrorHandlingService,
    roleSvcFactory,
    StatisticService,
    ComponentTypeService,
    DatatablePageService,
    RouteServiceHistoryService,
    routeServiceHistorySvcFactory,
    PreviousRouteService,
    WorkLogService,
    workLogSvcFactory,
    ActivityTypeService,
    activityTypeSvcFactory,
} from './service';
import { FormLocalDataService } from './service/form-local-data.service';
import { SideNavService } from './sidenav';
import {
    DialogConfirmComponent,
    DialogRoutetypeComponent,
    DialogReportComponent,
    DialogRouteInspectionComponent,
    DialogService
} from './dialog';

import { FormsModule } from '@angular/forms';
import { DialogFormComponent } from './dialog/dialog-form/dialog-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogRepairRouteComponent } from './dialog/dialog-repair-route/dialog-repair-route.component';
import { DialogReportShowComponent } from './dialog/dialog-report-show/dialog-report-show.component';
import { DialogComponentComponent } from './dialog/dialog-component/dialog-component.component';
import { PipesModule } from './pipes/pipes.module';
import {
    DialogRouteInspectionFinalizeComponent
} from './dialog/dialog-route-inspection-finalize/dialog-route-inspection-finalize.component';
import { RouteInspectionService, routeInspectionSvcFactory } from './service/route/route-inspection-service.factory';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DropdownHeaderService } from './template/dropdown-header/dropdown-header.service';
import { DialogGeneralComponent } from './dialog/dialog-general/dialog-general.component';
import { UserProfileService } from './service/generals';
import { SharedDatatableModule } from '@app/shared/shared-datatable.module';
import { SharedGalleryModule } from '@app/shared/shared-gallery.module';
import { AutofocusDirective } from './directives/auto-focus.directive';
import {InspectionTypeService, inspectionTypeSvcFactory} from '@app/core/service/generals/inspection-type.service';
import {BeltServiceCommentService} from './service/belt/belt-service-comment.service';
import { RouteServiceCommentService } from './service/route-service-comment.service';

@NgModule({
    declarations: [
        HeaderComponent,
        DialogConfirmComponent,
        DialogRoutetypeComponent,
        DialogReportComponent,
        DialogRouteInspectionComponent,
        DialogFormComponent,
        DialogRepairRouteComponent,
        DialogRepairRouteComponent,
        DialogReportShowComponent,
        DialogComponentComponent,
        DialogRouteInspectionFinalizeComponent,
        DialogGeneralComponent,
        AutofocusDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        UiSwitchModule,
        NgbModule.forRoot(),
        ToastrModule.forRoot({
            progressBar: true,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true
        }),
        DynamicFormModule.forRoot(),
        TranslateModule,
        PipesModule,
        SharedDatatableModule,
        SharedGalleryModule,
    ],
    exports: [
        HeaderComponent,
        DynamicFormModule,
        HttpClientModule,
        AutofocusDirective
    ],
    // Due to the dynamic nature of the DialogConfirmComponent, and its usage of ViewContainerRef#createComponent()
    // to create the component on the fly, the AOT compiler will not know to create the proper
    // ComponentFactory for your dialog component by default.
    //
    // You must include your dialog class in the list of entryComponents in your module definition
    // so that the AOT compiler knows to create the ComponentFactory for it.
    entryComponents: [
        DialogConfirmComponent,
        DialogRoutetypeComponent,
        DialogReportComponent,
        DialogRouteInspectionComponent,
        DialogFormComponent,
        DialogRepairRouteComponent,
        DialogReportShowComponent,
        DialogComponentComponent,
        DialogRouteInspectionFinalizeComponent,
        DialogGeneralComponent
    ],
    providers: [
        { provide: WINDOW, useFactory: windowFactory },
        AuthService,
        TokenService,
        AppService,
        ApiService,
        UtilService,
        AuthGuardService,
        CanDeactivateGuardService,
        CustomPreloadingStrategyService,
        DialogService,
        SideNavService,
        DropdownHeaderService,
        {
            provide: ParkService,
            useFactory: parkSvcFactory,
            deps: [ApiService]
        },
        {
            provide: UserService,
            useFactory: userSvcFactory,
            deps: [ApiService]
        },
        {
            provide: BeltCategoryService,
            useFactory: beltCategorySvcFactory,
            deps: [ApiService]
        },
        {
            provide: SupplierService,
            useFactory: supplierSvcFactory,
            deps: [ApiService]
        },
        {
            provide: BeltService,
            useFactory: beltSvcFactory,
            deps: [ApiService]
        },
        {
            provide: ServiceBeltService,
            useFactory: serviceBeltSvcFactory,
            deps: [ApiService]
        },
        {
          provide: ServiceBeltServiceHistory,
          useFactory: serviceBeltHistorySvcFactory,
          deps: [ApiService]
        },
        {
            provide: DailyReportService,
            useFactory: dailyReportSvcFactory,
            deps: [ApiService]
        },
        {
            provide: RoutePartCategoryService,
            useFactory: routePartCategorySvcFactory,
            deps: [ApiService]
        },
        {
            provide: RouteInspectionTypeService,
            useFactory: routeInspectionTypeSvcFactory,
            deps: [ApiService]
        },
        {
            provide: RouteService,
            useFactory: routeSvcFactory,
            deps: [ApiService]
        },
        {
            provide: BeltPartTypeService,
            useFactory: beltPartTypeSvcFactory,
            deps: [ApiService]
        },
        {
            provide: BeltPartCategoryService,
            useFactory: beltPartCategorySvcFactory,
            deps: [ApiService]
        },
        {
            provide: ServiceRouteService,
            useFactory: serviceRouteSvcFactory,
            deps: [ApiService]
        },
        {
            provide: ComplaintReportService,
            useFactory: complaintReportSvcFactory,
            deps: [ApiService]
        },
        {
            provide: RescueReportService,
            useFactory: rescueReportSvcFactory,
            deps: [ApiService]
        },
        {
            provide: IncidentReportService,
            useFactory: incidentReportSvcFactory,
            deps: [ApiService]
        },
        {
            provide: IncidentReportHandlingService,
            useFactory: incidentReportHandlingSvcFactory,
            deps: [ApiService]
        },
        {
            provide: RoleService,
            useFactory: roleSvcFactory,
            deps: [ApiService]
        },
        {
            provide: RouteInspectionService,
            useFactory: routeInspectionSvcFactory,
            deps: [ApiService]
        },
        {
          provide: InspectionTypeService,
          useFactory: inspectionTypeSvcFactory,
          deps: [ApiService]
        },
        {
            provide: RouteServiceHistoryService,
            useFactory: routeServiceHistorySvcFactory,
            deps: [ApiService]
        },
        {
          provide: WorkLogService,
          useFactory: workLogSvcFactory,
          deps: [ApiService]
        },
        {
          provide: ActivityTypeService,
          useFactory: activityTypeSvcFactory,
          deps: [ApiService]
        },
        UserProfileService,
        CountryService,
        StatisticService,
        BeltStatusService,
        CheckTypeService,
        SettingsService,
        ToastService,
        HttpErrorHandlingService,
        FormLocalDataService,
        ComponentTypeService,
        DatatablePageService,
        PreviousRouteService,
        BeltServiceCommentService,
        RouteServiceCommentService
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() private parentModule: CoreModule,
                private as: AppService) {
        moduleImportGuard(parentModule, 'CoreModule');
    }
}
