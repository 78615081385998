import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { UtilService } from './util.service';

@Injectable()
export class BaseFeatureModuleService<T> {

    constructor(public apiSvc: ApiService,
                public path: string,
                private reqParams: { getItems?: StringStringMap, getItem?: StringStringMap } = {}) {
    }

    getItems(): Observable<T[]> {
        return this.apiSvc
            .get(this.path, this.reqParams.getItems || {})
            .pipe(map((data: ApiResponseDataCollection) => data.Data));
    }

    getItemsWithParams(args: { params?: StringStringMap, path?: string, retAttr?: string }): Observable<T[] | ApiResponseDataCollection> {
        const tmpPath = args.path || this.path;
        const retAttr = args.retAttr || 'Data';
        return this.apiSvc
            .get(tmpPath, args.params || {})
            .pipe(map((data: any) => retAttr === 'this' ? data : data[retAttr]));
    }

    getItemsWithMeta(metaInfo: any): Observable<{list: T[], _meta: any}> {
        this.reqParams.getItems = Object.assign(this.reqParams.getItems, metaInfo);

        return this.apiSvc
            .get(this.path, this.reqParams.getItems || {})
            .pipe(map((data: ApiResponseDataCollection) => {
                if (data._meta) {
                    data._meta.pageNumber = data._meta.currentPage - 1;
                }
                return { list: data.Data, _meta: data._meta };
            }));
    }


    getItem(id: any): Observable<T> {
        return this.apiSvc.get(UtilService.pathId(this.path, id), this.reqParams.getItem || {});
    }

    getItemWithParams(id: number, args: { params?: StringStringMap, path?: string, retAttr?: string }): Observable<T> {
        return this.apiSvc.get(UtilService.pathId(this.path, id), args.params || {});
    }

    insert(item: T): Observable<any> {
        return this.apiSvc.post(this.path, item)
        .pipe(map((data: ApiResponseDataCollection) => data));
    }

    insertWithFormData(item: FormData) {
        return this.apiSvc.postFile(this.path, item)
        .pipe(map((data: ApiResponseDataCollection) => data));
    }

    update(item: T, suffix = ''): Observable<any> {
        return this.apiSvc.post(UtilService.pathId(this.path, item['Id'], suffix), item)
        .pipe(map((data: ApiResponseDataCollection) => data));
    }

    updateWithFile(item: FormData, Id: number, suffix = ''): Observable<any> {
      return this.apiSvc.postFile(UtilService.pathId(this.path, Id, suffix), item)
        .pipe(map((data: ApiResponseDataCollection) => data));
    }

    updateWithPath(item: T, path: string) {
        return this.apiSvc.post(path, item)
        .pipe(map((data: ApiResponseDataCollection) => data));
    }

    delete(Id: any): Observable<any> {
        return this.apiSvc.post(UtilService.pathIdDelete(this.path, Id))
        .pipe(map((data: ApiResponseDataCollection) => data));
    }

    cancel(Id: any): Observable<any> {
        return this.apiSvc.post(UtilService.pathIdCancel(this.path, Id))
        .pipe(map((data: ApiResponseDataCollection) => data));
    }

    setNewPath(path: string) {
        this.path = path;
    }

    export(params?: StringStringMap): Observable<any> {
        return this.apiSvc.get(`${this.path}/export/download`, params, true);
    }

    exportPdf(params?: StringStringMap): Observable<any> {
      return this.apiSvc.get(`${this.path}/export`, params, true);
    }

    changeStatusPPE(Id: any, params?: StringStringMap): Observable<any> {
       return this.apiSvc.post(UtilService.pathId(this.path, Id), params)
         .pipe(map((data: ApiResponseDataCollection) => data));
    }

    restoreEquipment(Id: any): Observable<any> {
      return this.apiSvc.post(UtilService.pathId(this.path, Id, '/restore'))
        .pipe(map((data: ApiResponseDataCollection) => data));
    }
}
