import { FieldControlService } from './../dynamic-form/field-control.service';
export class WorkTime {
    public Id: number;
    public ParkId: number;
    public UserId: number;
    public ActivityTypeId: number;
    public Date: string;
    public Data: any;
    public StartTime: string;
    public EndTime: string;
    public Duration: number;

    constructor(workTime: {
        Id?: number;
        ParkId?: number;
        UserId?: number;
        ActivityTypeId?: number;
        Date?: string;
        Data?: any;
        StartTime?: string;
        EndTime?: string;
        Duration?: number;
        }
    ) {
        this.Id = workTime.Id || 0;
        this.ParkId = +workTime.ParkId || 0;
        this.UserId = workTime.UserId || 0;
        this.ActivityTypeId = workTime.ActivityTypeId || 0;
        this.Date = workTime.Date || '';
        this.Data = workTime.Data || {};
        this.StartTime = workTime.StartTime || '';
        this.EndTime = workTime.EndTime || '';
        this.Duration = workTime.Duration || 0;
    }

    changeValuesToServer() {
    }
}
