import { FieldBase } from './field-base';
import { Options } from 'ng5-slider';
import { EventEmitter } from '@angular/core';

export class SliderField extends FieldBase<string> {
    controlType = 'slider';
    placeholder: string;
    sliderOption: Options = {};

    manualRefresh: EventEmitter<void> = new EventEmitter<void>();

    constructor(options: {} = {}) {
        super(options);
        this.sliderOption.floor = options['floor'] !== undefined ? options['floor'] : 1;
        this.sliderOption.step = options['step'] || 1;
        this.sliderOption.ceil = options['ceil'] || 10;
        this.sliderOption.disabled = options['disable'] || false;
        this.sliderOption.showTicks = options['showTicks'] || false;
        this.sliderOption.showSelectionBar = options['showSelectionBar'] || true;
        this.sliderOption.selectionBarGradient = options['selectionBarGradient'];
        this.sliderOption.getSelectionBarColor = options['getSelectionBarColor'];
        this.sliderOption.getPointerColor = options['getPointerColor'];
        this.sliderOption.showTicksValues = options['showTicksValues'] || false;
    }

    setDisable() {
        this.sliderOption = Object.assign({}, this.sliderOption, { disabled: true });
    }
}
