import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  HostListener,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef
} from '@angular/core';

import { AppService, DatatablePageService, UtilService } from '@app/core/service';
import { ComponentTitle } from '@app/shared/class';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ManageSidebarFilterService } from '@app/core/service/manage-sidebar-filter.service';
@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class SharedDatatableComponent extends ComponentTitle
  implements OnInit, OnChanges {
  @ViewChild('tableWrapper') tableWrapper;
  @ViewChild(DatatableComponent) datatable: DatatableComponent;
  @ViewChild('linkTpl')
  linkTpl: TemplateRef<any>;
  @ViewChild('dateTpl')
  dateTpl: TemplateRef<any>;
  @ViewChild('dateTimeTpl')
  dateTimeTpl: TemplateRef<any>;
  @ViewChild('descriptionTpl')
  descriptionTpl: TemplateRef<any>;
  @ViewChild('detailMobile')
  detailMobile: TemplateRef<any>;
  @ViewChild('actionTpl')
  actionTpl: TemplateRef<any>;
  @ViewChild('splittedHeaderTpl')
  splittedHeaderTpl: TemplateRef<any>;

  @Input()
  id: string;
  @Input()
  icon: string;
  @Input()
  rows: {}[];
  @Input()
  columns: any[];
  @Input()
  hasTitle = true;
  @Input()
  columnMode = 'force';
  @Input()
  rowClass: any;

  @Input()
  addBtn = true;
  @Input()
  addBtnTextTranslateKey = 'datatableactions.add';
  @Input()
  hasUpdatePermission = false;

  // Display
  @Input()
  isInnerTable = false;
  @Input()
  loading = true;
  @Input()
  showTable = false;
  @Input()
  hideTable = false;
  @Input()
  hasCard = true;
  @Input()
  isNotResponsive = false;
  @Input()
  responsiveColumnCondition: string;
  @Input()
  useSpinner = false;
  @Input()
  responsiveWidth = 800;
  @Input()
  defaultSortProp = '';
  @Input()
  defaultSortDir = 'asc';

  // Pagination
  @Input()
  offset = 0;
  @Input()
  count = 0;
  @Input()
  externalPaging = false;
  @Input()
  limit = 10;
  @Input()
  showFilters = false;
  @Input()
  showSidebarFilters = false;
  @Input()
  showWeekPagination = false;

  // Details
  @Input()
  showRowDetail = false;
  @Input()
  innerDetail: TemplateRef<any>;
  @Input() waitExport = false;
  @Input() showExport = false;
  @Input() isDisabledPrevButton = false;
  @Input() isDisabledNextButton = false;
  @Input() titleWeek = '';

  @Input()
  sidebarFiltersOpened = false;

  @Output()
  page = new EventEmitter();
  @Output()
  sort = new EventEmitter();
  @Output()
  toggleSidebarFilters = new EventEmitter();
  @Output()
  onExport = new EventEmitter();

  @Output()
  sidebarOpenedChange = new EventEmitter();

  @Output()
  onPrevButtonAction = new EventEmitter();
  @Output()
  onNextButtonAction = new EventEmitter();

  // Sort
  sortProp: string;
  sortDir: string;

  expanded: any = {};
  mobileColumns: any[];
  responsiveColumns: any[];
  appearedColumns: any[];
  tableWidth: number;
  private currentComponentWidth;
  responsiveMode = false;
  SelectionType = SelectionType;

  constructor(
    private route: ActivatedRoute,
    protected appSvc: AppService,
    private dtPageSvc: DatatablePageService,
    private cdRef: ChangeDetectorRef,
    public spinnerSvc: NgxSpinnerService,
    public utilSvc: UtilService,
    public manageSidebarSvc: ManageSidebarFilterService
  ) {
    super(appSvc);
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.hideTable) {
      if (this.useSpinner) {
        this.spinnerSvc.show();
      }

      this.offset = this.route.snapshot.queryParamMap.get('page')
        ? +this.route.snapshot.queryParamMap.get('page') - 1
        : 0;

      this.sortDir = this.route.snapshot.queryParamMap.get('dir')
        ? this.route.snapshot.queryParamMap.get('dir')
        : this.defaultSortDir;

      this.sortProp = this.route.snapshot.queryParamMap.get('prop')
        ? this.route.snapshot.queryParamMap.get('prop')
        : this.defaultSortProp;


      this.tableWidth = this.tableWrapper.nativeElement.clientWidth;

      this.generateColumns();
      this.initPageSvc();
      if (this.id) {
        this.dtPageSvc.page = this.offset + 1;
      }
    }
  }

  ngOnChanges(changes) {
    if (changes.rows) {
      this.rows = changes.rows.currentValue;
      setTimeout(() => {
        this.showTalbeFn();
      }, 1000);
    }

    if (changes.columns) {
      this.columns = changes.columns.currentValue;
      this.generateColumns();
      this.getColumnsBySize();
    }
  }

  private generateColumns() {
    this.mobileColumns = _.filter(
      this.columns,
      column => column.cellClass && column.cellClass.includes('mobile')
    );

    // tslint:disable-next-line:max-line-length
    this.responsiveColumns = _.map(
      _.filter(
        this.columns,
        column =>
          (column.cellClass && !column.cellClass.includes('mobile')) ||
          !column.cellClass
      ),
      col => {
        const item = {...col};
        if (item.name && item.maxWidth && !item.useMaxWidth) {
          delete item['maxWidth'];
        }
        return item;
      }
    );
    this.getColumnsBySize();
  }


  onPaginated(params) {
    if (!this.id) {
      return;
    }

    this.dtPageSvc.tableId = this.id;
    this.dtPageSvc.page = params.offset + 1;
    this.page.emit(params);
  }

  onSort(params): void {
    if (!this.id) {
      return;
    }

    this.dtPageSvc.sort = params.sorts;
    this.dtPageSvc.tableId = this.id;
    this.sort.emit(params);
  }

  fixBugColumnResize() {
    if (this.datatable && this.datatable.recalculate) {
      this.datatable.recalculate();
      this.datatable.recalculateColumns(this.columns);
      window.dispatchEvent(new Event('resize'));
    }
  }

  toggleExpandRow(row) {
    this.datatable.rowDetail.toggleExpandRow(row);
  }

  recalculate() {
    if (
      this.datatable &&
      this.datatable.recalculate &&
      this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth
    ) {
      this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;
      this.datatable.recalculate();
      this.cdRef.markForCheck();
    }
  }

  private initPageSvc() {
    if (!this.id) {
      return;
    }
    if (this.id && this.id !== this.dtPageSvc.tableId) {
      this.dtPageSvc.page = 1;
      this.dtPageSvc.sort = { prop: '', sort: '' };
    }
  }

  private showTalbeFn() {
    if (this.useSpinner) {
      this.spinnerSvc.hide();
    }
    this.showTable = true;
  }

  getMobileToggle() {
    return {
      width: 35,
      resizeable: false,
      sortable: false,
      draggable: false,
      canAutoResize: false,
      cellTemplate: this.detailMobile
    };
  }

  @HostListener('window:resize', ['$event'])
  getColumnsBySize(event?) {
    this.tableWidth = this.tableWrapper.nativeElement.clientWidth;
    this.responsiveMode = this.tableWidth < this.responsiveWidth;
    if (this.isNotResponsive) {
      this.appearedColumns = Object.assign([], this.columns);
      return;
    }

    if (this.responsiveMode) {
      this.appearedColumns = Object.assign([], this.responsiveColumns);
    } else {
      this.appearedColumns = Object.assign([], this.columns);
    }

    if (this.responsiveMode || this.showRowDetail) {
      this.appearedColumns.unshift(this.getMobileToggle());
    }
  }

  onToggleSidebarFilters() {
    this.sidebarFiltersOpened = !this.sidebarFiltersOpened;
    this.toggleSidebarFilters.emit();
  }

  exportXLS() {
    this.onExport.emit();
  }

  previewAction() {
    this.onPrevButtonAction.emit();
  }

  nextAction() {
    this.onNextButtonAction.emit();
  }

  splittedSort(prop: string, column: any) {
    column.prop = prop;
    const dir = column.dir === 'asc' ? column.dir = 'desc' : column.dir = 'asc';
    this.datatable.onColumnSort({ sorts: [{ prop, dir }] });
  }

  onSidebarOpenedChange(value) {
    this.sidebarOpenedChange.emit(value);
  }
}
